<template>
  <div>
    <loading
      :active.sync="isLoading"
      background-color="#332abb"
      :opacity="0.99"
      color="#fff"
      :is-full-page="fullPage"
    ></loading>

    <div class="main-content container mt-large user-page">
          <ModalKampanyaOlustur ref="modalKampanyaOlustur"></ModalKampanyaOlustur>
      <ul class="nav nav-tab d-flex flex-nowrap mt-3 mt-md-5">
        <li class="nav-item w-50 text-center position-relative">
          <a
            class="d-flex align-items-center justify-content-center active"
            data-toggle="tab"
            href="#kampanyalarim"
            aria-controls="kampanyalarim"
            aria-selected="true"
            >Kampanyalarım
          </a>
        </li>
        <li class="nav-item w-50 text-center position-relative">
          <a
            class="d-flex align-items-center justify-content-center"
            data-toggle="tab"
            href="#bagislarim"
            aria-controls="bagislarim"
            aria-selected="false"
            >Bağışlarım
          </a>
        </li>
        <li class="nav-item w-50 text-center position-relative">
          <a
            class="d-flex align-items-center justify-content-center"
            data-toggle="tab"
            href="#ayarlarim"
            aria-controls="ayarlarim"
            aria-selected="false"
            >Profil Ayarları
          </a>
        </li>
      </ul>
      <div class="user-container mt-3 mt-md-5 tab-content">
        <div class="tab-pane fade show active" id="kampanyalarim" role="tabpanel">
          <div v-if="campaignList.length > 0">
            <div class="listCampaings">
              <table>
                <thead>
                  <tr>
                    <th>Kampanya Başlığı</th>
                    <th>Açılış Tarihi</th>
                    <th>Kampanya Süresi</th>
                    <th class="text-right pr-4">Bağış Miktarı</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(camp, index) in campaignList" :key="index">
                    <td>

                      <router-link v-if="camp.status !== 'wait' " :to="{ name: 'KampanyaDetay', params: { slug: camp.slug } }"  class="text-underline">
                      {{ camp.title }}
                      </router-link>

                      <span v-else>{{ camp.title }}</span>
                    </td>
                    <td>
                      <b>{{ camp.approval_date | truncate(10) }}</b>
                    </td>
                    <td>
                      <b>{{ camp.end_date | truncate(10) }}</b>
                    </td>
                    <td class="text-right pr-4">
                      <p>{{ camp.price }}</p>
                    </td>
                    <td class="text-right">
                      <span class="onayda text-underline" v-if="camp.status === 'open'" @click="editCampaing($event)" :id="camp.id">Düzenle</span>
                      <span class="orange" v-if="camp.status === 'completed'">Süresi Dolmuş</span>
                      <span class="orange" v-if="camp.status === 'wait'">Onay Bekliyor</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="editCampaings">
              <div class="signup row justify-content-between">
              <div class="col-12 col-md-6">
                <div class="form-group w-100 mt-4">
                  <label class="d-none d-md-block">Kampanya Başlığı</label>
                  <input type="text" v-model="campaignForm.title" class="form-control col-12 col-md-10"/>
                </div>
                
                <div class="form-group w-100 mt-4">
                  <label class="d-none d-md-block">Kampanya Mesajı</label>
                  <textarea rows="4" type="text" v-model="campaignForm.campmsg" class="form-control col-12 col-md-10"></textarea>
                </div>
                
                <div class="form-group w-100 mt-4">
                  <label class="d-none d-md-block">Yetkililer İçin Mesajı</label>
                  <textarea rows="4" type="text" v-model="campaignForm.msg" class="form-control col-12 col-md-10"></textarea>
                </div>
              </div>
              
              <div class="col-12 col-md-5">

                <div class="form-group w-100 mt-4">
                  <label class="d-none d-md-block">İl</label>
                  <select class="custom-selectbox mw-100" v-model="campaignForm.city">
                    <option value="" disabled>İl Seçiniz</option>
                    <option v-for="(city, index) in allCities" :key="index" :value="city.id">{{city.title}}</option>
                  </select>
                </div>
                <div class="relative-checkbox mt-5 d-flex flex-column flex-md-row align-items-md-center justify-content-between">
                  <label class="custom-checkbox flex-1 mb-3 mb-md-0 d-flex align-items-center">Kampanya Bedeli
                    <input type="checkbox" v-model="campaignForm.kampanyaBedeli" @click="toggleInput(campaignForm.kampanyaBedeli, $event)">
                    <span class="checkmark"></span>
                  </label>

                  <div class="form-group flex-1 w-100" @click="enableInput($event)">
                    <the-mask class="form-control" type="tel" mask="#####" v-model="campaignForm.miktar"/>
                  </div>
                </div>

                <div class="relative-checkbox my-3 d-flex flex-column flex-md-row align-items-md-center justify-content-between">
                  <label class="custom-checkbox flex-1 mb-3 mb-md-0 mt-md-0 d-flex align-items-center">Etkinlik Bitiş Tarihi
                    <input type="checkbox" class="ozelgun"
                    @click="toggleInput(form.ozelgun, $event)"
                    v-model="campaignForm.ozelgun"
                    >
                    <span class="checkmark"></span>
                  </label>

                  <div class="form-group flex-1 w-100" @click="enableInput($event)">
                    <div class="position-relative input-icon d-flex align-items-center">
                      <flat-pickr class="form-control"
                      :config="configg"
                      v-model="campaignForm.tarih">
                      </flat-pickr>
                      <span class="svg-icon icons-calender"></span>
                    </div>
                  </div>
                </div>

                <div class="mt-4 form-group">
                  <div class="d-flex justify-content-between">
                    <label for="campaignVideoLink">Video Linki (Zorunlu Değil)</label>
                  </div>
                  <input type="text" class="form-control" v-model="campaignForm.video">
                </div>

                <div class="w-100 d-flex flex-column flex-md-row justify-content-between">
                  <label class="custom-checkbox d-flex align-items-center">Toplam Bağışı Gizle
                    <input id="bagisiGizle" type="checkbox" v-model="campaignForm.bagisiGizle">
                    <span class="checkmark"></span>
                  </label>

                  <label class="custom-checkbox d-flex align-items-center">Bağışçı Listesini Gizle
                    <input id="bagiscilariGizle" type="checkbox" v-model="campaignForm.bagiscilariGizle">
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
              
              <div class="col-12">
                <ButtonRounded class="float-right mt-4 ml-3" :Text="userSettingsBtn" :isRouting="false" @click.native="saveEditCampaing"/>
                <ButtonRounded class="float-right mt-4" Text="Vazgeç" color="blue" :isRouting="false" @click.native="closeCampaign"/>
              </div>
            </div>
            </div>
          </div>

          <div v-else>
            <p @click="modalKampanyaOlustur" class="custom-link">Kampanya oluşturmak için <span>tıklayın.</span></p>
          </div>
        </div>

        <div class="signup tab-pane fade" id="ayarlarim" role="tabpanel">
          <div
            class="
              w-100
              d-flex
              flex-column flex-md-row
              justify-content-between
              align-items-end
            "
          >
            <div class="user-pic d-flex align-items-center">
              <img v-if="prfImage" :src="prfImage" />
              <div class="ml-3">
                <div class="file-input">
                  <input
                    type="file"
                    accept="image/*"
                    @change="uploadImage($event)"
                    name="file-input"
                    id="file-input"
                    class="file-input__input"
                  />
                  <label
                    class="
                      button-rounded
                      d-flex
                      align-items-center
                      justify-content-center
                      small
                      blue
                    "
                    for="file-input"
                  >
                    <span>Fotoğraf Yükle</span></label
                  >
                </div>
                <p class="text-orange p-large">{{ imageErr }}</p>
                <p class="text-purple p-medium mt-3">
                  *Yüklenecek Fotoğraf (jpg, jpeg, png) formatında olmalıdır
                  ve<br />
                  400kb geçmemesi gerekmektedir.
                </p>
              </div>
            </div>
          </div>
          <div class="">
            <form @submit.prevent="updateUser" autocomplete="off" class="row d-flex flex-column flex-md-row mt-3">

            <div class="col-12 col-md-6">
              <div class="form-group d-flex align-items-center position-relative w-100 mt-3">
                <label class="col-4 d-none d-md-block">Adınız</label>
                <div class="w-100">
                  <input 
                    v-model="form.userName" 
                    v-on:input="removeSpace"
                    v-bind:class="{error: $v.form.userName.$error, valid: $v.form.userName.$dirty && !$v.form.userName.$invalid}"
                    type="text" class="form-control">
                  <p class="form-warning" v-if="!$v.form.userName.nameSurnameLength">
                    <span class="svg-icon icons-alert"></span> En az 2 karakter girmelisiniz</p>
                  <p class="form-warning" v-if="!$v.form.userName.minLength">
                    <span class="svg-icon icons-alert"></span> En az 2 karakter girmelisiniz</p>
                  <p class="form-warning" v-if="!$v.form.userName.required">
                    <span class="svg-icon icons-alert"></span> Bu alan zorunludur.</p>
                </div>
              </div>
              <div class="form-group d-flex align-items-center position-relative w-100 mt-3">

                <label for="userSurname" class="col-4 d-none d-md-block">Soyadınız</label>
                <div class="w-100">
                  <input 
                    v-model="form.userSurname" 
                    v-on:input="removeSpace"
                    v-bind:class="{error: $v.form.userSurname.$error, valid: $v.form.userSurname.$dirty && !$v.form.userSurname.$invalid}"
                    type="text" class="form-control">
                  <p class="form-warning" v-if="!$v.form.userSurname.nameSurnameLength">
                    <span class="svg-icon icons-alert"></span> En az 2 karakter girmelisiniz</p>
                  <p class="form-warning" v-if="!$v.form.userSurname.minLength">
                    <span class="svg-icon icons-alert"></span> En az 2 karakter girmelisiniz</p>
                  <p class="form-warning" v-if="!$v.form.userSurname.required">
                    <span class="svg-icon icons-alert"></span> Bu alan zorunludur.</p>

                </div>

                  <!--  -->
                <!-- <label class="col-4 d-none d-md-block">Soyadınız</label>
                <input type="text" v-model="form.userSurname" class="form-control col-12 col-md-8" aria-describedby="" placeholder="Soyadınız"/> -->
              </div>
              <div class="form-group d-flex align-items-center position-relative w-100 mt-3">
                <label class="col-4 d-none d-md-block">Doğum Tarihiniz</label>
                <flat-pickr
                  class="form-control col-12 col-md-8"
                  :config="config"
                  placeholder="Doğum Tarihiniz"
                  v-model="birth_date"
                >
                </flat-pickr>
              </div>
              <div class="form-group d-flex align-items-center position-relative w-100 mt-3">
                <label class="col-4 d-none d-md-block">Eski Şifreniz</label>
                <span
                  class="svg-icon"
                  v-bind:class="isShown ? 'icons-eye' : 'icons-eye-closed'"
                  @click="showPassword($event)"
                ></span>
                <input
                  class="form-control col-12 col-md-8"
                  type="password"
                  v-model="form.userPass"
                  v-on:input="removeSpace"
                  v-bind:class="{
                    error: $v.form.userPass.$error,
                    valid:
                      $v.form.userPass.$dirty && !$v.form.userPass.$invalid,
                  }"
                />
              </div>
                <div class="form-group">
                  <p v-if="errorMess.length > 0" class="ml-3">{{errorMess}}</p>
                </div>
            </div>
            <div class="col-12 col-md-6">
              <div
                class="
                  form-group
                  d-flex
                  align-items-center
                  position-relative
                  w-100
                  mt-3
                "
              >
                <label class="col-4 d-none d-md-block">E-posta</label>
                <div class="w-100">
                  <input 
                    v-model="form.userMail" 
                    v-on:keydown='key'
                    placeholder="E-posta Adresiniz"
                    v-bind:class="{error: $v.form.userMail.$error, valid: $v.form.userMail.$dirty && !$v.form.userMail.$invalid}"
                    inputmode="email" type="email" class="form-control">
                  <p class="form-warning" v-if="!$v.form.userMail.email">
                    <span class="svg-icon icons-alert"></span>Yanlış mail formatı</p>
                  <p class="form-warning" v-if="!$v.form.userMail.required">
                    <span class="svg-icon icons-alert"></span>Bu alan zorunludur.</p>
                </div>
              </div>
              <div
                class="
                  form-group
                  d-flex
                  align-items-center
                  position-relative
                  w-100
                  mt-3
                "
              >
                <label class="col-4 d-none d-md-block">Telefon Numarası</label>
                <div class="w-100">
                  <the-mask
                    name="phone" id="frmPhoneNumA" autocomplete="tel"
                    mask="0(###) ### ## ##"
                    placeholder="(---) --- -- --"
                    v-model="form.userPhone" 
                    v-bind:class="{error: $v.form.userPhone.$error, valid: $v.form.userPhone.$dirty && !$v.form.userPhone.$invalid}"
                    type="tel" class="form-control" />
                  <p class="form-warning" v-if="!$v.form.userPhone.required">
                    <span class="svg-icon icons-alert"></span>Bu alan zorunludur.</p>
                  <p class="form-warning" v-if="!$v.form.userPhone.minLength">
                    <span class="svg-icon icons-alert"></span>Yanlış telefon formatı</p>
                </div>
                
              </div>
              <div
                class="
                  form-group
                  d-flex
                  align-items-center
                  position-relative
                  w-100
                  mt-3
                "
              >
                <label class="col-4 d-none d-md-block">Adres</label>
                <input
                  type="text"
                  class="form-control col-12 col-md-8"
                  aria-describedby=""
                  v-model="form.userAdress"
                  placeholder="Adres"
                />
              </div>
              <div
                class="
                  form-group
                  d-flex
                  align-items-center
                  position-relative
                  w-100
                  mt-3
                "
              >
                <label class="col-4 d-none d-md-block">Yeni Şifreniz</label>
                <span
                  class="svg-icon"
                  v-bind:class="isShown ? 'icons-eye' : 'icons-eye-closed'"
                  @click="showPassword($event)"
                ></span>
                <input
                  class="form-control col-12 col-md-8"
                  type="password"
                  v-model="form.passRepeat"
                  v-on:input="removeSpace"
                  v-bind:class="{
                    error: $v.form.passRepeat.$error,
                    valid:
                      $v.form.passRepeat.$dirty && !$v.form.passRepeat.$invalid,
                  }"
                />
              </div>
          
              <ButtonRounded
                class="float-right mt-4"
                :Text="userSettingsBtn"
                color=""
                
                :isRouting="false"
              />
            </div>

            </form>
          </div>
        </div>

        <div class="tab-pane fade" id="bagislarim" role="tabpanel">
          <div
            class="mb-5"
        
          >
         
            <h4 class="profile-sub-title">Düzenli Bağışlarım</h4>
            <table v-if="this.duzenliDonations">
              <thead>
                <tr>
                  <th>Başlama Tarihi</th>
                  <th>Yenileme Tarihi</th>
                  <th>Bağış Tipi</th>
                  <th class="text-right pr-4">Bağış Miktarı</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <donationModal
                  ref="donationCancelModal"
                  :datta="this.cancelId"
                ></donationModal>
                <tr
                  v-for="(datta, i) in duzenliDonations" :key="i"
                  v-bind:class="{ disabled: datta.status == 'CANCEL' }"
                >
                  <td>
                    {{ datta.date | truncate(10) }}
                  </td>
                  <td>
                    {{ datta.renewal_date }}
                  </td>
                  <td>
                    {{ datta.donation_desc }}
                    <span v-if="datta.city.length > 0"
                      >( {{ datta.city }} )</span
                    >
                  </td>
                  <td>
                    <p class="text-right pr-4">{{ datta.price }} TL</p>
                  </td>
                  <td class="text-right">
                    <button
                      type="button"
                      v-if="datta.status == 'ACTIVE'"
                      @click="showModalDonation($event)"
                      :data-id="datta.payment_id"
                    >
                      Bağış İptali
                    </button>
                    <span class="orange" v-else>İptal Edildi</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-else>
               <div class="mb-5" >
                  Düzenli bağışınız bulunmamaktadır. 
                </div>
            </div>
            <h4 class="profile-sub-title mt-5">Tek Seferlik Bağışlarım</h4>

            <table v-if="this.tekseferDonations">
              <thead>
                <tr>
                  <th>Başlama Tarihi</th>
                  <th>Bağış Tipi</th>
                  <th class="text-right">Bağış Miktarı</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, i) in tekseferDonations" :key="i">
                  <td>
                    {{ data.date | truncate(10) }}
                  </td>
                  <td>{{ data.donation_desc }}</td>
                  <td>
                    <p class="text-right">{{ data.price }} TL</p>
                  </td>
                </tr>
              </tbody>
            </table>
          <div class="mb-5" v-else>
             Tekseferlik bağışınız bulunmamaktadır. 
          </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>

    <div class="spacer clearfix"></div>
    <div class="spacer clearfix"></div>
    <FooterLanding />
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import FooterLanding from "@/components/FooterLanding";
import ButtonRounded from "@/components/ButtonRounded";
import donationModal from "@/components/donationCancelModal";
import flatPickr from "vue-flatpickr-component";
import ModalKampanyaOlustur from "@/components/ModalKampanyaOlustur";
import "flatpickr/dist/flatpickr.css";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";

const nameSurnameLength = (value) => {
  let isValid = true;
  value.split(" ").forEach((e) => {
    if (e.length < 2) {
      isValid = false;
    }
  });
  return isValid;
};
export default {
  name: "Profile",
  components: {
    FooterLanding,
    Loading,
    ButtonRounded,
    flatPickr,
    donationModal,
    ModalKampanyaOlustur
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      isShown: false,
      allCities: '',
      date: null,
      userSettingsBtn: "Kaydet",
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        //altFormat: "m / j / Y",
        altInput: false,
        allowInput: false,
        readOnly: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
        locale: Turkish,
      },
      configg: {
        wrap: true, // set wrap to true only when using 'input-group'
        //altFormat: "m / j / Y",
        altInput: false,
        allowInput: false,
        readOnly: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
        locale: Turkish,
        defaultDate: ["2016-10-20"],
      },
      birth_date: "",
      form: {
        userName: "",
        userSurname: "",
        userMail: "",
        userAdress: "",
        userPhone: "",
        userPass: "",
      },
       campaignForm: {
        title: "",
        campmsg: "",
        msg: "",
        miktar: "",
        video: "",
        tarih: "",
        bagisiGizle: '',
        bagiscilariGizle: '',
        ozelgun: false,
        kampanyaBedeli: false,
        id : 0,
      },
      enddate: '',
      campaignList: {},
      donationList: {},
      profileDetail: {},
      falseMsg: "",
      imgPath: "",
      prfImage: "",
      loginStatus: false,
      duzenliDonations: {},
      tekseferDonations: {},
      donationError: "",
      imageErr: "",
      cancelId: "",
      errorMess: "",
    };
  },
  validations: {
    form: {
      userName: {
        required,
        nameSurnameLength,
        minLength: minLength(2),
      },
      userSurname: {
        required,
        nameSurnameLength,
        minLength: minLength(2),
      },
      userMail: {
        required,
        email: email,
      },
      userPhone: {
        required,
        minLength: minLength(10),
      },
      userPass: {
        minLength: minLength(2),
      },
      passRepeat: {
        minLength: minLength(2),
        // sameAsPassword: sameAs("userPass"),
      }
    },
  },
    metaInfo() {
    return {
      title: 'Bilim Seferberliği - İmkanları sınırlı, hayalleri sınırsız çocukları bilim ile buluştur.',
      meta: [
        {
          vmid: "description",
          name: "description",
          //content: this.metaData.description,
        },
        // {name: 'keywords', content: this.meta_data.meta_keywords},
        // {property: 'og:image', content: this.meta_data.meta_image},
        { property: "og:title", content: 'Bilim Seferberliği - İmkanları sınırlı, hayalleri sınırsız çocukları bilim ile buluştur.' },
        { property: "og:description", content: 'Okullara geri dönen imkanları sınırlı, hayalleri sınırsız çocuklarımıza Bilim Seti ulaştırılması için destek ol!' },
        { property: "og:url", content: window.location.href },
        { name: "twitter:title", content: 'Bilim Seferberliği - İmkanları sınırlı, hayalleri sınırsız çocukları bilim ile buluştur.' },
        { name: "twitter:description", content: 'Okullara geri dönen imkanları sınırlı, hayalleri sınırsız çocuklarımıza Bilim Seti ulaştırılması için destek ol!' },
        { name: "twitter:url", content: window.location.href},
        // {name: 'twitter:image', content: this.meta_data.meta_image},
        // {property: 'og:image:width', content: '540'},
        // {property: 'og:image:height', content: '282'},
      ],
    };
  },
  mounted() {
    this.getCampaignList();
    this.formFocus();
    this.getDonationList();
    this.getProfileSettings();
    this.tabSelect();
    this.getCities();
    // let stt = this.$store.state.loginState;
  },
  watch: {
    "$store.state.loginState": function () {
      let stt = this.$store.state.loginState;
      if (stt == false) {
        this.$router.push("/");
      } else {
      }
    },
  },
  methods: {
    toggleInput(e, item){
      let $target = $(item.target).closest("label").siblings().find("input");
      $($target).toggleClass("enabled");
 
      // $target.prop('disabled', function(i, v) { return !v; });
      $target.val('');

    },
    enableInput(item){
      let $checkbox = $(item.target).closest(".relative-checkbox").find("input")[0];
      let $input = $(item.target).closest(".relative-checkbox").find("input")[1];

      if(!$($input).hasClass("enabled")){
        $($input).addClass("enabled");
        $($checkbox).prop( "checked", true );
      }

    },
    editCampaing(e){
      let campId = e.target.id;
      let params = {};
      params["campaign_id"] = campId;
      let token = localStorage.getItem("user-token");
      let tokenize = JSON.parse(token);
         let headers = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "Application/json",
          Authorization: `Bearer ${tokenize.token}`,
        },
      };
      this.$api.getCampaignEditDetail(params, headers).then(res =>{
        
        this.campaignForm.title = res.detail.title,
        this.campaignForm.city = res.detail.city_id,
        this.campaignForm.video = res.detail.video,
        this.campaignForm.campmsg = res.detail.message,
        this.campaignForm.msg = res.detail.owner_message,
        this.campaignForm.miktar = res.detail.price,
        this.campaignForm.tarih = res.detail.end_date,
        this.campaignForm.bagisiGizle = res.detail.total_donation_show === "false" ? false : true,
        this.campaignForm.bagiscilariGizle = res.detail.donation_list_show === "false" ? false : true,
        this.campaignForm.ozelgun = res.detail.end_date_status,
        this.campaignForm.kampanyaBedeli = (this.campaignForm.miktar > 0) ? true : false;
        this.campaignForm.id = res.detail.id;
        //this.end_date = this.campaignForm.tarih.substring(0,10);

        //this.configg.defaultDate = this.end_date;

        $(".listCampaings").hide();
        $(".editCampaings").show();
      })
    },
    saveEditCampaing(){
      let token = localStorage.getItem("user-token");
      let tokenize = JSON.parse(token);
      let headers = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: `Bearer ${tokenize.token}`,
       },
      }
      let params = {};
      params["user_id"] = tokenize.user.id;
      params["campaign_id"] = this.campaignForm.id;
      params["city_id"] = this.campaignForm.city;
      params["title"] = this.campaignForm.title;
      params["message"] = this.campaignForm.campmsg;
      params["video"] = this.campaignForm.video;
      params["price"] = this.campaignForm.miktar;
      params["owner_message"] = this.campaignForm.msg;
      // params["donation_show"] = this.campaignForm.bagisiGizle;
      // params["donation_list"] = this.campaignForm.bagiscilariGizle;
      // params["donation_show"] = this.campaignForm.bagisiGizle === false ? "true" : "false";
      // params["donation_list"] = this.campaignForm.bagiscilariGizle === true ? "true" : "false";
      params["donation_show"] = document.querySelector('#bagisiGizle').checked === false ? "false" : "true";
      params["donation_list"] = document.querySelector('#bagiscilariGizle').checked === false ? "false" : "true";
      params["end_date"] = this.campaignForm.tarih;
  
      this.$api.editCampaign(params, headers).then(res=>{
        
        // $(".listCampaings").show();
        // $(".editCampaings").hide();
        if(res.original.success){
          this.getCampaignList();
          $(".listCampaings").show();
          $(".editCampaings").hide();
          
          this.userSettingsBtn = "Kaydedildi";
          setTimeout(() => {
            this.userSettingsBtn = "Kaydet";
          }, 2000);

        }else{
          
        }
      });
    },
    closeCampaign(){
      $(".listCampaings").show();
      $(".editCampaings").hide();
    },
    getCities(){
      this.$api.getAllCities().then(response =>{
        this.allCities = response;
      });
    },
    removeSpace: function (event) {
      this.form.userName = this.form.userName
        .replace(/\s\s+/g, " ")
        .trimStart();
    },
    key: function (event) {
      if (event.keyCode === 32) {
        event.preventDefault();
      }
    },
    formFocus() {
      $(".form-control").on({
        focus: function () {
          $(this).parent().addClass("focused");
        },

        blur: function () {
          $(this).parent().removeClass("focused");
        },
      });
    },
    showModalDonation(event) {
      this.cancelId = $(event.target).data("id");
      this.$refs.donationCancelModal.openModal();
    },
    uploadImage(event) {
      let token = localStorage.getItem("user-token");
      let tokenize = JSON.parse(token);
      let headers = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "Application/json",
          Authorization: `Bearer ${tokenize.token}`,
        },
      };
      // let params = {};
      // params['image'] =  event.target.files[0];
      let data = new FormData();
      data.append("image", event.target.files[0]);
      data.append("user_id", tokenize.user.id);
      this.$api.avatarUpload(data, headers).then((response) => {
        if (response.success) {
          let params = {};
          params["user_id"] = tokenize.user.id;
          this.$api.getUserDetail(params, headers).then((response) => {
            this.prfImage = response.image;
            tokenize.user.image = this.prfImage;
            localStorage.setItem("user-token", JSON.stringify(tokenize));
            this.imageErr = "";
          });
        } else {
          this.imageErr = response.message;
        }
      });
    },
    tabSelect() {
      $(function () {
        var hash = window.location.hash;
        hash && $('ul.nav a[href="' + hash + '"]').tab("show");

        $(".nav-item a").click(function (e) {
          $(this).tab("show");
          var scrollmem = $("body").scrollTop();
          window.location.hash = this.hash;
          $("html,body").scrollTop(scrollmem);
        });

      });
    },
    modalKampanyaOlustur() {
      this.$refs.modalKampanyaOlustur.openModal();
      this.$refs.modalKampanyaOlustur.getSwiper();
    },
    showPassword(e) {
      var $target = $(e.target).siblings("input");

      if ($target.attr("type") == "password") {
        $target.attr("type", "text");
        this.isShown = true;
      } else {
        $target.attr("type", "password");
        this.isShown = false;
      }
    },
    getCampaignList() {
      let status = localStorage.getItem("login-status");
      this.loginStatus = status;
      let tokenk = localStorage.getItem("user-token");
      let tokenize = JSON.parse(tokenk);
      let headers = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "Application/json",
          Authorization: `Bearer ${tokenize.token}`,
        },
      };
      let params = {};
      params["user_id"] = tokenize.user.id;
      this.$api.userCampaignList(params, headers).then((response) => {

        if (response.success == false) {
          this.falseMsg = response.message;
        } else {
          this.campaignList = response;
        }
      });
    },
    getDonationList() {
      let token = localStorage.getItem("user-token");
      let tokenize = JSON.parse(token);
      let headers = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "Application/json",
          Authorization: `Bearer ${tokenize.token}`,
        },
      };
      let params = {};
      params["user_id"] = tokenize.user.id;
      this.$api.userDonationList(params, headers).then((response) => {
        if (response.success == false) {
          this.donationError = response.message;
        } else {
          this.donationList = response.donationPayment;
          this.duzenliDonations = sortArrayByDate(response.donationPayment.düzenli);
          this.tekseferDonations = sortArrayByDate(response.donationPayment.teksefer);
        }
          
        function makeArray(obj){
          let sortable = [];
          for (var i in obj) {
            sortable.push(obj[i]);
          }
          return sortable;
        }        

        function sortArrayByDate(sortArray) {
          let arr = makeArray(sortArray);

          arr.sort(function(a, b){
            return new Date(a.date) - new Date(b.date);
          }).reverse();

          return arr;
        }

        


      });
    },
    getProfileSettings() {
      let token = localStorage.getItem("user-token");
      let tokenize = JSON.parse(token);
      let headers = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "Application/json",
          Authorization: `Bearer ${tokenize.token}`,
        },
      };
      let params = {};
      params["user_id"] = tokenize.user.id;
      this.$api.getUserDetail(params, headers).then((response) => {
        this.profileDetail = response;
        this.form.userName = response.name;
        this.form.userSurname = response.surname;
        this.birth_date = response.birth_date;
        this.form.userMail = response.email;
        this.form.userAdress = response.adress;
        this.prfImage = response.image;
        this.form.userPhone = response.phone;

      });
    },
    updateUser() {

      this.$v.$touch();
      if(!this.$v.form.$invalid){
  
        let token = localStorage.getItem("user-token");
        let tokenize = JSON.parse(token);
        let headers = {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-type": "Application/json",
            Authorization: `Bearer ${tokenize.token}`,
          },
        };
        let params = {};
        params["user_id"] = tokenize.user.id;
        params["name"] = this.form.userName;
        params["surname"] = this.form.userSurname;
        params["phone"] = this.form.userPhone;
        params["adress"] = this.form.userAdress;
        params["birth_date"] = this.birth_date;
        params["email"] = this.form.userMail;
        params["old_password"] = this.form.userPass;
        params["new_password"] = this.form.passRepeat;
        params["image"] = this.imgPath;
        this.$api.updateUserDetail(params, headers).then((response) => {
          if(response.success){
            this.userSettingsBtn = "Kaydedildi";
            setTimeout(() => {
              this.userSettingsBtn = "Kaydet";
            }, 2000);
          }else{
            this.errorMess = response.message;
          }
        });
      }else{
        
      }

    },
    trimHours(data) {
      // let data = "123213213131"

      return data.slice(0, 8);
    },
  },
};
</script>
<style lang="scss">
.mt-large {
  margin-top: 150px;
}
.user-container {
  .user-pic {
    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
  }

  .svg-icon {
    height: 1.5rem;
    width: 1.5rem;
    background: var(--purpleDark);
    position: absolute;
    right: 1rem;
    z-index: 1;
  }

  .profile-sub-title {
    color: var(--orange);
    font-size: 1.15rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  table {
    width: 100%;
    thead {
      border-bottom: 4px solid var(--purpleDark);
      th {
        font-size: 0.8rem;
        line-height: 1.18rem;
        color: var(--purpleDark);
        padding-bottom: 10px;
        font-weight: 400;
      }
    }
    tbody {
      tr.disabled {
        td {
          color: #9794b5;
        }
      }
      td {
        font-size: 1rem;
        color: var(--purpleDark);
        padding: 1.5rem 0;
        font-weight: 400;
        b {
          font-weight: 600;
        }
        p {
          font-weight: 600;
          font-size: 1.4rem;
        }
        button {
          font-size: 1rem;
          text-decoration: underline;
          background: transparent;
          color: var(--purpleDark);
        }
        .onayda {
          color: var(--green);
          cursor: pointer;
        }
        .orange {
          color: var(--orange);
        }
        border-bottom: 1px solid rgba(24, 15, 100, 0.12);
      }
    }
  }
  input.flatpickr-input {
    background: var(--blueLight) !important;
    border: 2px solid var(--blueLight);
    color: #fff;
  }
  .file-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  .file-input {
    label {
      max-width: 200px;
    }
  }

  .file-input__label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    padding: 10px 12px;
    background-color: #4245a8;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  }

  .file-input__label svg {
    height: 16px;
    margin-right: 4px;
  }
}
@media (max-width: 574px) {
  #bagislarim {
    overflow-x: scroll;
    table {
      width: 500px;
    }
  }
  #kampanyalarim {
    overflow-x: scroll;
    table {
      width: 500px;
    }
  }
}
.editCampaings{
  display: none;
}

.custom-link{
  color: var(--purpleDark);
  cursor: pointer;
  
  span{
    font-weight: 600;
  }
}
</style>
