<template>
  <transition name="fade">
    <div class="modal donation-cancel-modal" v-if="show">

      <div class="modal-message">
            <div class="modal-header">
                <h1>Bağışı İptal Et</h1>
                
                <p  @click="closeModal">Kapat</p>
            </div>
            <p class="d-none">{{datta}}</p>
            <p v-html="modalMsg"></p>
                <div class="w-100 text-center d-flex flex-column justify-content-center" v-if="cancelled === false || cancelled === 'false'">
                    <p>Bağışınızı iptal etmek istediğinizden emin misiniz ?</p>
                    <div class="d-flex mx-auto">
                        <ButtonRounded class="mt-4"
                            Text="Evet"
                            btnSize="middle" 
                            :isRouting="false"
                            @click.native="cancelDonation"
                        />
                        <ButtonRounded class="mt-4 ml-3"
                            Text="Hayır"
                            btnSize="middle" 
                            :isRouting="false"
                            @click.native="closeModal"
                        />
                    </div>
                    <p class="mt-3 px-3" v-if="errMsg.length > 0" v-html="errMsg"></p>
                </div>
                <p v-else-if="cancelled === true || cancelled === 'true'">Bağışınız iptal edildi !</p>
      </div>

    </div>
  </transition>
</template>

<script>
import ButtonRounded from "@/components/ButtonRounded";

export default {
  name: "SocialLoginModal",
  props: [ "datta" , "modalMsg" , "success"],
  components: { ButtonRounded },
  data() {
    return {
      show: false,
      cancelData: this.datta,
      cancelled: false,
      errMsg: '',
    };
  },
  methods: {
    closeModal() {
      this.show = false;
      this.errMsg = '';
      // document.querySelector("body").classList.remove("overflow-hidden");
    },
    openModal() {
      this.show = true;
      document.querySelector("body").classList.add("overflow-hidden");
    },
    cancelDonation(){
        let params = {};
        params["payment_id"] = this.datta;
        this.$api.cancelDonation(params).then(response =>{
            if(response.success){
                this.cancelled = true;
                setTimeout(() => {
                  window.location.reload();
                }, 1500);
            }else{
                this.errMsg = response.msg;
            }
        });
    }
  },
};
</script>


<style lang="scss">
.donation-cancel-modal{
    z-index: 99999 !important;
    position: fixed !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background: rgba(24, 15, 100 , 0.9);
}
.modal-message{
  width: 50%;
  position: relative;
  height: 50%;
  background: #fff;
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  flex-direction: column;
  color: #000;
  p{
    font-size: 1.4rem;
  }
  .modal-header{
      background:  var(--whiteOld);
      display: flex;
      align-items: center;
      position: absolute;
      top: 0px;
      justify-content: space-between;
      width: 100%;
        p{
            text-decoration: underline;
            font-size: 1rem;
            cursor: pointer;
            color: var(--purpleDark);
        }
        h1{
              font-size: 1.65rem;
            color: var(--purpleDark);
            font-weight: 400;
        }
  }
}
</style>